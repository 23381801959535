import React, { useState, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import * as styles from './styles.module.scss';
import Layout from '../../components/layout';
import ImageBanner from '../../components/ImageBanner';
import SEO from '../../components/seo';
// import SectionTitle from '../../components/home/section-title';
import { motion } from 'framer-motion';
const Icon = () => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    className="md:h-5 md:w-5"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#000000"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <circle cx="12" cy="10" r="3" />
    <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
  </svg>;
};

const Office = (props) => {
  const {
    location,
    data,
  } = props;
  const [isMobile, setIsMobile] = useState(false);
  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
    return () => window.removeEventListener("resize", setMobile);
  }, [setMobile]);
  const [isEnWebsite, setIsEnWebsite] = useState(false);
  useEffect(() => {
    if (location.pathname.indexOf('/en') > -1) {
      setIsEnWebsite(true);
    } else {
      setIsEnWebsite(false);
    }
  }, [location.pathname]);
  const copywriting = data.contentfulCopywriting;
  const { title, image } = data.contentfulCopywriting.workplace.offices || {};
  const officeList = data.contentfulCopywriting.workplace.offices.nodes || [];
  const [currentOffice, setCurrentOffice] = useState([]);
  const [activeOffice, setActiveOffice] = useState('CN');
  // 根据office的Grouped给officeList分组
  const groupedOffices = officeList.reduce((acc, office) => {
    const tag = office?.Grouped
    if (!acc[tag]) {
      acc[tag] = [];
    }
    acc[tag].push({
      area: office.Area,
      city: office.City,
      country: office.Country,
      grouped: office.Grouped,
      address: office.Address,
      tag,
    });
    // console.log('acc', acc);
    return acc;
  }, {});
  const areaMap = {
    // na: groupedOffices.na?.[0]?.area,
    // me: groupedOffices.me?.[0].area,
    // eu: groupedOffices.eu?.[0].area,
    // ap: groupedOffices.ap?.[0].area,
    'DE': (isMobile || isEnWebsite) ? groupedOffices['DE']?.[0].grouped : groupedOffices['DE']?.[0].country,
    'UK': (isMobile || isEnWebsite) ? groupedOffices['UK']?.[0].grouped : groupedOffices['UK']?.[0].country,
    'NL': (isMobile || isEnWebsite) ? groupedOffices['NL']?.[0].grouped : groupedOffices['NL']?.[0].country,
    'UAE': (isMobile || isEnWebsite) ? groupedOffices['UAE']?.[0].grouped : groupedOffices['UAE']?.[0].country,
    'KSA': (isMobile || isEnWebsite) ? groupedOffices['KSA']?.[0].grouped : groupedOffices['KSA']?.[0].country,
    'US': (isMobile || isEnWebsite) ? groupedOffices['US']?.[0].grouped : groupedOffices['US']?.[0].country,
    'CA': (isMobile || isEnWebsite) ? groupedOffices['CA']?.[0].grouped : groupedOffices['CA']?.[0].country,
    'CN': (isMobile || isEnWebsite) ? groupedOffices['CN']?.[0].grouped : groupedOffices['CN']?.[0].country,
    'JP': (isMobile || isEnWebsite) ? groupedOffices['JP']?.[0].grouped : groupedOffices['JP']?.[0].country,
    'KR': (isMobile || isEnWebsite) ? groupedOffices['KR']?.[0].grouped : groupedOffices['KR']?.[0].country,
    'INDIA': (isMobile || isEnWebsite) ? groupedOffices['INDIA']?.[0].grouped : groupedOffices['INDIA']?.[0].country,
    'AU': (isMobile || isEnWebsite) ? groupedOffices['AU']?.[0].grouped : groupedOffices['AU']?.[0].country,
  };

  useEffect(() => {
    // console.log('groupedOffices0', groupedOffices);
    if (groupedOffices) {
      setCurrentOffice(groupedOffices['CN']);
      setActiveOffice('CN');
    }
  }, [data]);
  const handleOfficeClick = (office) => {
    setCurrentOffice(groupedOffices[office]);
    setActiveOffice(office);
  };


  return (
    <div className={styles.workplacePage}>
      <Layout {...props} hideSearch copywriting={copywriting}>
        <SEO title={data.contentfulCopywriting.workplace.seo.title} keywords={data.contentfulCopywriting.workplace.seo.keywords} description={data.contentfulCopywriting.workplace.seo.description} />
        <ImageBanner content={data.contentfulCopywriting.workplace.banner} />
        <div className={`${styles.box} pb-[72px] md:pb-[0]`}>
          <div className={`${styles.myBox} w-full overflow-x-hidden`}>
            {/* <div className={styles.title} style={{ maxWidth: '1200px' }}>
              {title}
            </div> */}
            {/* <SectionTitle title={title} /> */}
            <div className="section-title text-black max-w-[1200px] m-auto">
              <p className={`${isEnWebsite ? 'font-bold' : 'font-semibold'} text-2xl md:text-3xl lg:text-[40px] lg:leading-[48px]`} dangerouslySetInnerHTML={{ __html: title }} />
            </div>
            <div className={styles.imageBox}>
              <div>
                <img
                  className={styles.image}
                  src={image}
                  // width={1760}
                  // height={1000}
                  alt="image"
                />
              </div>
              <div className={`${styles.positioning} ${styles.positioningDE}`} onClick={() => handleOfficeClick('DE')} onMouseMove={() => handleOfficeClick('DE')} />
              <div className={`${styles.positioning} ${styles.positioningUK}`} onClick={() => handleOfficeClick('UK')} onMouseMove={() => handleOfficeClick('UK')} />
              <div className={`${styles.positioning} ${styles.positioningNL}`} onClick={() => handleOfficeClick('NL')} onMouseMove={() => handleOfficeClick('NL')} />
              <div className={`${styles.positioning} ${styles.positioningUAE}`} onClick={() => handleOfficeClick('UAE')} onMouseMove={() => handleOfficeClick('UAE')} />
              <div className={`${styles.positioning} ${styles.positioningKSA}`} onClick={() => handleOfficeClick('KSA')} onMouseMove={() => handleOfficeClick('KSA')} />
              <div className={`${styles.positioning} ${styles.positioningUS}`} onClick={() => handleOfficeClick('US')} onMouseMove={() => handleOfficeClick('US')} />
              <div className={`${styles.positioning} ${styles.positioningCA}`} onClick={() => handleOfficeClick('CA')} onMouseMove={() => handleOfficeClick('CA')} />
              <div className={`${styles.positioning} ${styles.positioningCN}`} onClick={() => handleOfficeClick('CN')} onMouseMove={() => handleOfficeClick('CN')} />
              <div className={`${styles.positioning} ${styles.positioningJP}`} onClick={() => handleOfficeClick('JP')} onMouseMove={() => handleOfficeClick('JP')} />
              <div className={`${styles.positioning} ${styles.positioningKR}`} onClick={() => handleOfficeClick('KR')} onMouseMove={() => handleOfficeClick('KR')} />
              <div className={`${styles.positioning} ${styles.positioningINDIA}`} onClick={() => handleOfficeClick('INDIA')} onMouseMove={() => handleOfficeClick('INDIA')} />
              <div className={`${styles.positioning} ${styles.positioningAU}`} onClick={() => handleOfficeClick('AU')} onMouseMove={() => handleOfficeClick('AU')} />
              <span
                onMouseMove={() => handleOfficeClick('DE')}
                onClick={() => handleOfficeClick('DE')}
                className={`${styles.area} left-[42.4%] top-[12%] ${!isMobile && !isEnWebsite ? 'md:left-[46%] lg:left-[46%] xl:left-[46%] 2xl:left-[46%] md:top-[9.8%] xl:top-[10.4%] 2xl:top-[11.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[46%] lg:left-[46%] xl:left-[46%] 2xl:left-[46%] md:top-[9.8%] xl:top-[10.4%] 2xl:top-[11.2%]' : ''}`}
              // style={{ left: !isMobile && !isEnWebsite ? '46%' : !isMobile && isEnWebsite ? '46%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['DE']}</span>
                {/* {activeOffice === 'DE' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'DE' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('UK')}
                onClick={() => handleOfficeClick('UK')}
                className={`${styles.area} left-[44%] top-[-4.8%] ${!isMobile && !isEnWebsite ? 'md:left-[38.8%] lg:left-[39.2%] xl:left-[39.8%] 2xl:left-[40.3%] md:top-[2.6%] xl:top-[3.4% 2xl:top-[4.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[39%] lg:left-[39.8%] xl:left-[40.4%] 2xl:left-[40.8%] md:top-[2.6%] xl:top-[3.4% 2xl:top-[4.2%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'UK' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaUK}`}
              // style={{ left: !isMobile && !isEnWebsite ? '40%' : !isMobile && isEnWebsite ? '40.4%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['UK']}</span>
                {/* {activeOffice === 'UK' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'UK' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('NL')}
                onClick={() => handleOfficeClick('NL')}
                className={`${styles.area} left-[33.8%] top-[5.8%] ${!isMobile && !isEnWebsite ? 'md:left-[35.8%] lg:left-[36.2%] xl:left-[37.6%] 2xl:left-[38.5%] md:top-[19%] xl:top-[19.8%] 2xl:top-[19.6%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[36%] lg:left-[37.4%] xl:left-[38.8%] 2xl:left-[39.2%] md:top-[19%] xl:top-[19.8%] 2xl:top-[19.6%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'NL' ? !isMobile ? '-ml-[28px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaNL}`}
              // style={{ left: !isMobile && !isEnWebsite ? '37.8%' : !isMobile && isEnWebsite ? '38.8%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['NL']}</span>
                {/* {activeOffice === 'NL' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'NL' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('UAE')}
                onClick={() => handleOfficeClick('UAE')}
                className={`${styles.area} left-[50%] top-[19.6%] ${!isMobile && !isEnWebsite ? 'md:left-[46%] lg:left-[52.6%] xl:left-[53.5%] 2xl:left-[54.4%] md:top-[36.8%] xl:top-[37.4%] 2xl:top-[37.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[52.5%] lg:left-[53.6%] xl:left-[54.5%] 2xl:left-[54.9%] md:top-[37%] xl:top-[37.4%] 2xl:top-[37.2%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'UAE' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaUAE}`}
              // style={{ left: !isMobile && !isEnWebsite ? '49.5%' : !isMobile && isEnWebsite ? '54.5%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['UAE']}</span>
                {/* {activeOffice === 'UAE' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'UAE' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('KSA')}
                onClick={() => handleOfficeClick('KSA')}
                className={`${styles.area} left-[51.2%] top-[5.8%] ${!isMobile && !isEnWebsite ? 'md:left-[47%] lg:left-[47.8%] xl:left-[49%] 2xl:left-[49.8%] md:top-[21%] xl:top-[22%] 2xl:top-[22.5%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[49.2%] lg:left-[50.4%] xl:left-[51.2%] 2xl:left-[51.6%] md:top-[20.8%] xl:top-[22%] 2xl:top-[22.5%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'KSA' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaKSA}`}
              // style={{ left: !isMobile && !isEnWebsite ? '49.2%' : !isMobile && isEnWebsite ? '51.2%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['KSA']}</span>
                {/* {activeOffice === 'KSA' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'KSA' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('US')}
                onClick={() => handleOfficeClick('US')}
                className={`${styles.area} left-[13.4%] top-[16.8%] ${!isMobile && !isEnWebsite ? 'md:left-[13.5%] lg:left-[14.7%] xl:left-[15.4%] 2xl:left-[15.8%] md:top-[30.4%] xl:top-[30.6%] 2xl:top-[30.6%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[14.2%] lg:left-[15.2%] xl:left-[16%] 2xl:left-[16.2%] md:top-[30.4%] xl:top-[30.6%] 2xl:top-[30.6%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'US' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaUS}`}
              // style={{ left: !isMobile && !isEnWebsite ? '15.5%' : !isMobile && isEnWebsite ? '16%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['US']}</span>
                {/* {activeOffice === 'US' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'US' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('CA')}
                onClick={() => handleOfficeClick('CA')}
                className={`${styles.area} left-[16.4%] top-[-0.8%] ${!isMobile && !isEnWebsite ? 'md:left-[12%] lg:left-[12.9%] xl:left-[13.8%] 2xl:left-[14.5%] md:top-[8%] xl:top-[9%] 2xl:top-[9.6%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[13.6%] lg:left-[14.3%] xl:left-[14.9%] 2xl:left-[15.3%] md:top-[8%] xl:top-[9%] 2xl:top-[9.6%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'CA' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaCA}`}
              // style={{ left: !isMobile && !isEnWebsite ? '13.9%' : !isMobile && isEnWebsite ? '14.9%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['CA']}</span>
                {/* {activeOffice === 'CA' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'CA' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('CN')}
                onClick={() => handleOfficeClick('CN')}
                className={`${styles.area} left-[64.2%] top-[6.8%] ${!isMobile && !isEnWebsite ? 'md:left-[66.4%] lg:left-[67.8%] xl:left-[68.6%] 2xl:left-[68.8%] md:top-[22.4%] xl:top-[23.4%] 2xl:top-[24.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[67.4%] lg:left-[68.2%] xl:left-[68.8%] 2xl:left-[69.2%] md:top-[22.4%] xl:top-[23.4%] 2xl:top-[24.2%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'CN' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaCN}`}
              // style={{ left: !isMobile && !isEnWebsite ? '68.4%' : !isMobile && isEnWebsite ? '68.8%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['CN']}</span>
                {/* {activeOffice === 'CN' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'CN' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('JP')}
                onClick={() => handleOfficeClick('JP')}
                className={`${styles.area} left-[78%] top-[15.3%] ${!isMobile && !isEnWebsite ? 'md:left-[75.2%] lg:left-[76.2%] xl:left-[77%] 2xl:left-[77.5%] md:top-[29%] xl:top-[29.2%] 2xl:top-[29%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[76%] lg:left-[77%] xl:left-[77.7%] 2xl:left-[77.7%] md:top-[28.8%] xl:top-[29.2%] 2xl:top-[29%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'JP' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaJP}`}
              // style={{ left: !isMobile && !isEnWebsite ? '77.2%' : !isMobile && isEnWebsite ? '77.7%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['JP']}</span>
                {/* {activeOffice === 'JP' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'JP' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('KR')}
                onClick={() => handleOfficeClick('KR')}
                className={`${styles.area} left-[76%] top-[1%] ${!isMobile && !isEnWebsite ? 'md:left-[72.8%] lg:left-[73.6%] xl:left-[74.2%] 2xl:left-[74.6%] md:top-[14.6%] xl:top-[15.6%] 2xl:top-[16.4%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[73%] lg:left-[74%] xl:left-[74.8%] 2xl:left-[75.1%] md:top-[14.6%] xl:top-[15.6%] 2xl:top-[16.4%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'KR' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaKR}`}
              // style={{ left: !isMobile && !isEnWebsite ? '74.2%' : !isMobile && isEnWebsite ? '74.8%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['KR']}</span>
                {/* {activeOffice === 'KR' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'KR' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('INDIA')}
                onClick={() => handleOfficeClick('INDIA')}
                className={`${styles.area} left-[60.8%] top-[20%] ${!isMobile && !isEnWebsite ? 'md:left-[62%] lg:left-[62.8%] xl:left-[63.6%] 2xl:left-[64%] md:top-[38.4%] xl:top-[38.6%] 2xl:top-[38.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[61%] lg:left-[62%] xl:left-[63.1%] 2xl:left-[63.5%] md:top-[38.4%] xl:top-[38.6%] 2xl:top-[38.2%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'INDIA' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaINDIA}`}
              // style={{ left: !isMobile && !isEnWebsite ? '63.6%' : !isMobile && isEnWebsite ? '63.1%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['INDIA']}</span>
                {/* {activeOffice === 'INDIA' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'INDIA' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <span
                onMouseMove={() => handleOfficeClick('AU')}
                onClick={() => handleOfficeClick('AU')}
                className={`${styles.area} left-[79.6%] top-[33.6%] ${!isMobile && !isEnWebsite ? 'md:left-[76.8%] lg:left-[77.6%] xl:left-[78.6%] 2xl:left-[79%] md:top-[63.4%] xl:top-[63.6%] 2xl:top-[63.2%]' : ''} ${!isMobile && isEnWebsite ? 'md:left-[78%] lg:left-[79.6%] xl:left-[80.4%] 2xl:left-[80.6%] md:top-[63.4%] xl:top-[63.6%] 2xl:top-[63.2%]' : ''} transition-all duration-400 ease-ou ${activeOffice === 'AU' ? !isMobile ? '-ml-[14px]' : 'ml-[0px]' : 'ml-[0px]'}`}
              // className={`${styles.area} ${styles.areaAU}`}
              // style={{ left: !isMobile && !isEnWebsite ? '78.6%' : !isMobile && isEnWebsite ? '80.4%' : null }}
              >
                <span className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[12px] md:text-[20px]`}>{areaMap['AU']}</span>
                {/* {activeOffice === 'AU' && ( */}
                <div className={`flex items-center overflow-hidden transition-all duration-400 ease-out ${activeOffice === 'AU' ? 'w-auto h-auto md:ml-[8px] ml-[1px]' : 'w-[0px] h-[0px]'}`}>
                  <Icon />
                </div>
                {/* )} */}
              </span>
              <div className={styles.areaListMBox}>
                {/* <div
                  // className="h-[220px] w-[342px] md:h-[240px] md:w-[349px] lg:h-[320px]  xl:w-[416px] xl:h-[328px]  overflow-hidden  md:bg-white bg-[#FFFFFFBF] border border-[#E4E7EC] absolute md:bottom-1 bottom-[3%] md:left-0 md:py-6 left-1/2 -translate-x-1/2 md:translate-x-0 p-4 pr-[10px]"
                  className={`${styles.areaListBox}`}
                > */}
                <div
                  // className={`h-full overflow-auto md:p-3 ${styles.customScrollbar}`}
                  className={`${styles.contentBox}`}
                >
                  <motion.div
                    key={currentOffice[0]?.country} // 使用 key 强制重新渲染
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className={`${styles.areaTitle}`}
                    style={{ marginBottom: '30px' }}
                  >
                    {currentOffice && currentOffice[0]?.country}
                  </motion.div>
                  <div className={`${styles.officeList}  ${styles.officeBoxScrollbar}`}>
                    {currentOffice?.map((office, index) => (
                      <div className={styles.officeBox} key={index}>
                        <motion.div
                          key={office?.city} // 使用 key 强制重新渲染
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className={styles.officeCity}
                        >
                          {office?.city}
                        </motion.div>
                        <motion.div
                          key={office?.address} // 使用 key 强制重新渲染
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.5 }}
                          className={`${styles.officeAddress} font-[300]`}
                        >
                          {office?.address}
                        </motion.div>
                      </div>
                    ))
                    }
                  </div>
                  {/* <p
                    // className="font-bold md:text-[20px]  text-[16px] leading-[20px]"
                    className={`${styles.areaTitle}`}
                    style={{ marginBottom: '30px' }}
                  >
                    {currentOffice && currentOffice[0]?.country}
                  </p> */}

                </div>
                {/* </div> */}
              </div>

            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Office;

export const query = graphql`
query campusQuery($node_locale: String) {
  contentfulCopywriting(node_locale: {eq: $node_locale}) {
    node_locale
    common {
      hot_btn {
        link
        show_paths
        hot_btn_text
      }
    }
    footer {
      brands {
        link
        image
      }
      followus {
        title
        list {
          type
          image
          imageact
          imageact
          clickFunction
          link
        }
      }
      copyright
      logo
    }
    header {
      links {
        menuId
        name
        url
      }
    }
    search {
      search_btn_text
      placeholder
      options {
        category
        description
        url
      }
    }
    workplace {
      seo {
        title
        keywords
        description
      }
      banner {
        pc
        mobile
        jump_link
        title
        mobiletitle
        icontext
        desc
      }
      offices {
        title
        image
        nodes {
          Grouped
          Area
          Country
          City
          Address
        }
      }
    }
  }
}
`;
