// extracted by mini-css-extract-plugin
export var Gazebo_boderM__CmrxL = "styles-module--Gazebo_boderM__CmrxL--cec4d";
export var area = "styles-module--area--cbe31";
export var areaListMBox = "styles-module--areaListMBox--14fb8";
export var areaTitle = "styles-module--areaTitle--c13b9";
export var box = "styles-module--box--9450a";
export var coloredLine = "styles-module--coloredLine--8b125";
export var contentBox = "styles-module--contentBox--fbb89";
export var customScrollbar = "styles-module--customScrollbar--9927e";
export var customSwiper = "styles-module--customSwiper--826e9";
export var detail = "styles-module--detail--ce6a4";
export var grayLine = "styles-module--grayLine--05405";
export var hide = "styles-module--hide--89458";
export var image = "styles-module--image--89f3e";
export var imageBox = "styles-module--imageBox--78ab9";
export var myBox = "styles-module--myBox--07c64";
export var officeAddress = "styles-module--officeAddress--1386f";
export var officeBox = "styles-module--officeBox--38c8d";
export var officeBoxScrollbar = "styles-module--officeBoxScrollbar--4466e";
export var officeCity = "styles-module--officeCity--2cef6";
export var officeCountry = "styles-module--officeCountry--e2b70";
export var officeList = "styles-module--officeList--9b775";
export var positioning = "styles-module--positioning--807ec";
export var positioningAU = "styles-module--positioningAU--84538";
export var positioningCA = "styles-module--positioningCA--b1aeb";
export var positioningCN = "styles-module--positioningCN--f610c";
export var positioningDE = "styles-module--positioningDE--8d7bc";
export var positioningINDIA = "styles-module--positioningINDIA--e56f8";
export var positioningJP = "styles-module--positioningJP--1c02f";
export var positioningKR = "styles-module--positioningKR--4597e";
export var positioningKSA = "styles-module--positioningKSA--b8672";
export var positioningNL = "styles-module--positioningNL--113da";
export var positioningUAE = "styles-module--positioningUAE--5cce4";
export var positioningUK = "styles-module--positioningUK--baa17";
export var positioningUS = "styles-module--positioningUS--b1347";
export var show = "styles-module--show--72155";
export var swiperSlideActive = "styles-module--swiperSlideActive--1608c";
export var swiperSlideWithOverlay = "styles-module--swiperSlideWithOverlay--5ebdd";
export var title = "styles-module--title--69d96";
export var workplacePage = "styles-module--workplacePage--76fc8";